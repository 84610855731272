require('team-admin')
angular.module('team-admin')
  .directive('editPlayer', function() {

    return {
      restrict: 'A',
      scope: {
        cancel: '=',
        confirm: '=',
        team: '=',
        playerModel: '<?player',
        dialogOptions: '=',
        context: '=',
        gaCategory: '=',
        gaPlayerType: '='
      },
      template: require('./edit-player.html'),
      controller: function(pageViewHandler, _, $q, $scope, $element, $routeParams, Player, Position, getEnum, TeamInvite, SiteMembership) {
        pageViewHandler.fireEvent('RosterEditModal.Open')

        var team = $scope.team
        var orgId = team.org_details.id

        $scope.$watch('context', function(context) {
          if (!context) return
          $scope.TYPE = $scope.context.toUpperCase()
          $scope.confirm_label = $scope.TYPE + '.EDIT.confirm'
          $scope.metadataProperty = Player.contextTypeV3[$scope.context] + '_variables'
        })

        var restrictedFields = [
          'Gender',
          'Date of Birth'
        ]

        $scope.readOnly = function(field) {
          var claimed = $scope.playerModel.invitation.status === 'accepted'
          return claimed && _.contains(restrictedFields, field)
        }

        $scope.$watch('playerModel', function(playerModel) {
          $scope.playerModel ||= Player.createInstance({
            org_id: $scope.team.org_details.id,
            roster_id: _.get($scope.team, ['rosters', 0, 'id']),
            roster_role: Player.contextTypeV3[$scope.context]
          })
          $scope.player = angular.copy($scope.playerModel)
        })

        $scope.$watch('team', function(team) {
          Position.findAll({ team_id: team.id })
            .then($scope.setAs('positions'))
        })

        $scope.savePlayer = function() {
          if ($scope.playerEditForm.$invalid) return // in case they submitted with pending changes by hitting Enter

          var player = _.omit($scope.player, 'persona') // temp front-end properties
          player.name = `${ player.first_name } ${ player.last_name }`

          _.extend($scope.playerModel, player)

          $scope.editing = true
          $scope.failed = false
          return $q.all({ player: Player.create($scope.playerModel, { ...Player.v3Options, upsert: true, ...(player.id && { omit: ['roster_id'] }) }) }) // TS errors if roster_id is sent for edit
            .then(!player.id && sendInvitation.bind(null, player))
            .then(savePlayerSuccess, savePlayerError)
        }

        var nonInvitedPlayers = []
        function sendInvitation(player, createdPlayer) {
          createdPlayer = _.get(createdPlayer, 'player', createdPlayer) // this gets wrapped somewhere somehow
          if (!player.email) return createdPlayer
          return TeamInvite.create({ email_address: player.email }, {
            endpoint: `v3/roster_personas/${ createdPlayer.id }/invitations`
          })
            .then(invite => _.extend(createdPlayer, { invitation: invite }))
            .catch(() => nonInvitedPlayers.push(createdPlayer) && createdPlayer)
        }

        function savePlayerSuccess(player) {
          $scope.editing = false
          $scope.confirm(player, nonInvitedPlayers)
          createSiteMembership(player)
        }

        function savePlayerError() {
          $scope.editing = false
          $scope.failed = true
          $scope.confirm_label = $scope.TYPE + '.EDIT.confirm_retry'
        }

        function createSiteMembership(createdPlayer) {
          if (!createdPlayer?.persona_id || !orgId) return
          SiteMembership.create({
            org_id: orgId,
            persona_id: createdPlayer.persona_id,
            status: 'active'
          }, {
            endpoint: `/site_memberships`
          })

            .then(
              createSiteMembershipSuccess.bind(null)
            )
          return createdPlayer
        }

        function createSiteMembershipSuccess(siteMembershipResult) {
          return siteMembershipResult
        }

        $scope.genderOptions = getEnum('ENUMS.gender')

        $scope.dialogOptions.preCloseCallback = function() {
          if ($scope.editing) return false
        }

        $scope.callTracking = function(name){
          pageViewHandler.fireEvent('Roster.Edit.'+name , 8)
        }
      }
    }
  })
