angular.module('launch-darkly-flags', [])

  .service('launchDarklyFlags', function(_, $q, $rootScope, $route, $routeParams, LaunchDarklyService, renderContext, showError, Team) {

    var launchDarklyFlags = {}
    var teamId
    var deferred

    function update() {
      if (renderContext.layout === 'error') return
      if (/^\d*$/.test($routeParams?.teamId || '') || $routeParams.teamId === teamId) return

      deferred = $q.defer()
      launchDarklyFlags.promise = deferred.promise

      getTeam()
        .then(getFeatures)
        .then(parse)
        .catch(handleError)
        .finally(done)

    }

    // TEAM (loading team here to get the org_id)

    function getTeam() {
      teamId = $routeParams.teamId
      return teamId ? Team.find(teamId) : $q.reject(new Error('No team id found.'))
    }

    function getFeatures(team) {
      return LaunchDarklyService.client(team?.org_details?.id)
    }

    function parse(ldClient) {
      clearFeatures()
      launchDarklyFlags.newTeamCenterEnabled = ldClient.variation('team-center', false)
      launchDarklyFlags.gameAddEditModal = ldClient.variation('season-game-add-edit-modal', false)
      deferred.resolve(launchDarklyFlags)
    }

    function handleError(error) {
      clearFeatures()
      deferred.reject()
      showError(error)
    }

    function clearFeatures() {
      for (var key in launchDarklyFlags) { delete launchDarklyFlags[key] }
    }

    function done() {
      launchDarklyFlags.loaded = true
    }

    $rootScope.$on('$routeChangeSuccess', update)
    if ($route.current) update()

    return launchDarklyFlags

  })
  .run(function($rootScope, launchDarklyFlags) {
    $rootScope.constructor.prototype.launchDarklyFlags = launchDarklyFlags
  })
