/* eslint-disable */
var loadContext = require('./load-context').loadContext

// FIXME: replace with Babel preset-env with builtIns: 'usage'
require('core-js/shim')

// note: "require"-ing moment itself is not necessary, because moment-timezone loads the core moment library itself,
// and extends it to include the timezone functionality
window.moment = require('moment-timezone/builds/moment-timezone-with-data.js')

require('lodash')
require('jquery')
require('promise-polyfill')
require('angular')
require('angular-animate')
require('angular-messages')
require('angular-resource')
require('angular-route')
require('angular-sanitize')
require('angular-cookies')
window.i18n = require('i18next/lib/dep/i18next.js')
require('se-i18ng/i18ng.js')
window.firebase = require('firebase')
require('angularfire/dist/angularfire.js')
require('js-data')
require('js-data-angular')
require('ng-dialog')
require('select2')
window.Tether = require('tether')
require('checklist-model')
require('angular-drag-and-drop-lists')
require('@claviska/jquery-minicolors/jquery.minicolors.js')
require('angular-minicolors')
require('clipboard/dist/clipboard.js')
require('ngclipboard')
require('angular-linkify')
require('regenerator-runtime')

require('./shared.js')

loadContext(require.context('../shared/config', true, /^((?!-test).)*\.js$/))
require('../shared/services/mobile-nav.service.js')
require('../shared/components/alerts/alerts-service.js')
loadContext(require.context('../shared/components/chat', true, /^((?!-test).)*\.js$/))
require('../shared/components/confirm-dialog/confirm-dialog.component.js')
require('../shared/components/current-user.js')
require('../shared/components/current-user-service.js')
loadContext(require.context('../shared/components/date', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/datepicker', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/debounce', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/debounce-callback', true, /^((?!-test).)*\.js$/))
require('../shared/components/deferred.js')
require('../shared/components/dialog.js')
loadContext(require.context('../shared/components/dynamic-directive', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/file-upload', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/form-helpers', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/get-enum', true, /^((?!-test).)*\.js$/))
require('../shared/components/google-places-directive.js')
require('../shared/components/iframe-feedback-modal/iframe-feedback-modal.component.js')
loadContext(require.context('../shared/components/image-preview-modal', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/keyboard-helper', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/multiselect-dropdown', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/modal-nav-bar', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/oauth-callback', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/poll-until-resolved', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/pagination', true, /^((?!-test).)*\.js$/))
require('../shared/components/pl-ellipsis.directive.js')
loadContext(require.context('../shared/components/poll-until-resolved', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/regform', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/registration', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/render-context', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/route-title', true, /^((?!-test).)*\.js$/))
require('../shared/components/scope-helpers.js')
loadContext(require.context('../shared/components/select-helper', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/select2', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/set-as', true, /^((?!-test).)*\.js$/))
require('../shared/components/set-focus.js')
loadContext(require.context('../shared/components/set-while', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/show-error', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/slider', true, /^((?!-test).)*\.js$/))
require('../shared/components/sn-async.js')
require('../shared/components/sn-extra-events.js')
require('../shared/components/sn-popover-directive.js')
loadContext(require.context('../shared/components/spinner', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/components/spinner-button', true, /^((?!-test).)*\.js$/))
// require('../shared/components/sticky-header.js')
require('../shared/components/svg-sprites.js')
loadContext(require.context('../shared/components/try-background-image', true, /^((?!-test).)*\.js$/))
require('../shared/components/underscore.js')
loadContext(require.context('../shared/components/user-settings', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/directives/sn-validate', true, /^((?!-test).)*\.js$/))
loadContext(require.context('../shared/filters', true, /^((?!-test).)*\.js$/))
require('../shared/interceptors/global-session-interceptor.js')
window.jstz = require('../shared/other_components/jstz.js').jstz
require('../shared/other_components/modded-ng-img-crop.js')
require('../shared/other_components/ng-quill.js')
require('../shared/other_components/ngyn-select2.js')
require('../shared/other_components/select2v3.js')
require('../shared/services/affiliation-service.js')
require('../shared/services/attachment-service.js')
require('../shared/services/geocoder/geocoder.js')
require('../shared/services/gtm.service.js')
require('../shared/services/page-view.service.js')
require('../shared/services/http-401-static-interceptor.js')
require('../shared/services/local-relations.js')
require('../shared/services/navigation-events.js')
require('../shared/services/nested-resource.js')
require('../shared/services/oauth-me-service.js')
require('../shared/services/oauth-state-service.js')
require('../shared/services/organization-service.js')
require('../shared/services/persona-service.js')
require('../shared/services/preload-script.js')
require('../shared/services/program-configuration-service.js')
require('../shared/services/program-service.js')
require('../shared/services/printed-card.service.js')
loadContext(require.context('../shared/services/print-service', true, /^((?!-test).)*\.js$/))
require('../shared/services/role-assignments-service.js')
require('../shared/services/rostering-personas-service.js')
require('../shared/services/roster-players-service.js')
require('../shared/services/se-bar.service.js')
require('../shared/services/site-memberships-service.js')
require('../shared/services/sn-globals-service.js')
require('../shared/services/sports-service.js')
require('../shared/services/squad-locker-stores-service.js')
require('../shared/services/subvenue-service.js')
require('../shared/services/time-zones-service.js')
require('../shared/services/user-preferences-service.js')
require('../shared/services/user-service-personas-service.js')
require('../shared/services/ux.service.js')
require('../shared/services/venue-service.js')
require('../shared/utilities/color-helper.js')
require('../shared/utilities/console-helpers.js')
require('../shared/utilities/file-helper.js')
require('../shared/utilities/wrap-digest.js')

require('./other_components/jquery.timepicker.js')
require('./other_components/timepicker.js')
require('./.se-ui-components/se-ui-components.js')
require('../shared/.transpiled/shared.transpiled')
